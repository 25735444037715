@import 'styles/helpers';

.siteHeader {
  display: grid;
  grid-template-columns: calcRem(180) auto;
  align-items: center;
  padding-top: calcRem(25);
  padding-bottom: calcRem(25);
  position: relative;
  z-index: 100;

  @media (max-width: $breakpoint-lg) {
    padding: calcRem(20) calcRem(15);
    margin-bottom: calcRem(30);
  }

  nav {
    margin-left: auto;

    @media (max-width: $breakpoint-lg) {
      position: fixed;
      top: 0;
      bottom: 0;
      left: calcRem(70);
      right: 0;
      transform: translateX(120%);
      transition: all 0.3s cubic-bezier(.25,.8,.25,1);
      background-color: #fff;
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
      padding: calcRem(130) calcRem(40) calcRem(40);
      overflow-y: auto;
      z-index: 100;
      text-align: center;

      &.active {
        transform: translateX(0);
      }
    }
  }
}

.logoSection {
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
}

.logoSpan {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
}

.headerNavWrapper {
  display: flex;
  margin-left: auto;
  align-items: center;
  padding: 0;
  margin: 0;

  @media (max-width: $breakpoint-lg) {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: calcRem(5);
  }

  li {
    list-style-type: none;
    margin-left: calcRem(20);

    @media (max-width: $breakpoint-lg) {
      margin: 0;
    }
  }
}

.headerMenuItem {
  font-weight: 400;
  padding: calcRem(8) 0;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease-in-out;
  font-size: calcRem(15);

  @media (max-width: $breakpoint-lg) {
    font-size: calcRem(16);
  }

  a {
    color: $primary-text-color;
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }

  &:hover, &.active {
    border-bottom: 2px solid $primary-color;
    cursor: pointer;

    a {
      text-decoration: none;
      opacity: 0.6;
    }
  }
}

.headerMenuItemCta {
  @media (max-width: $breakpoint-lg) {
    padding-bottom: calcRem(20);
  }
}

.langSelect {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: calcRem(10);
  align-items: center;

  @media (max-width: $breakpoint-lg) {
    justify-content: center;
  }
}

.headerMenuSeparator {
  width: calcRem(2);
  height: calcRem(20);
  background-color: $primary-color;
  display: block;
  margin: 0 calcRem(10);

  @media (max-width: $breakpoint-lg) {
    width: 100%;
    max-width: calcRem(100);
    height: calcRem(2);
    margin: calcRem(20) auto;
  }
}

.linkIntroText {
  font-size: calcRem(13);
  font-weight: 300;
  display: inline-block;
  margin-right: calcRem(6);
}

.mobileMenuButton {

  @media (min-width: $breakpoint-lg) {
    display: none;
  }

  position: fixed;
  top: 0;
  right: 0;
  width: calcRem(72);
  height: calcRem(72);
  padding: 0;
  border: 0;
  box-shadow: none;
  background: none;
  transition: all 0.3s ease-in-out;
  color: #fff;
  z-index: 1000;

  &.active {
    width: calcRem(82);
    height: calcRem(82);
    cursor: pointer;
  }

  .menuIconContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: calcRem(10);
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.active {

  }
}

.itemLabelWithIcon {
  display: inline-flex;
}