@import 'styles/helpers';

.publicFooter {
  background-color: $tag-background-color;
  margin: calcRem(-20) 0 0;
  padding: calcRem(100) 0 calcRem(80);
}

.footerContent {
  display: grid;
  grid-template-columns: calcRem(280) 1fr;
  grid-gap: calcRem(100);

  @media (max-width: $breakpoint-sm) {
    grid-template-columns: 1fr;
    grid-gap: calcRem(30);
  }
}

.footerLeft {

  .logo {
    width: calcRem(220);
    filter: grayscale(1);
    transition: all 0.3s ease-in-out;
    margin-bottom: calcRem(20);

    &:hover {
      filter: grayscale(0);
    }
  }
}

.footerMenu {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: calcRem(60);
  padding-top: calcRem(10);

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: $breakpoint-sm) {
    grid-template-columns: 1fr;
    grid-gap: calcRem(20);
  }
}

.menuGroup {
  padding: 0;
  margin: 0;

  li {
    list-style-type: none;
    margin-bottom: calcRem(10);

    @media (max-width: $breakpoint-sm) {
      margin-bottom: calcRem(5);
    }
  }

  .groupHeader {
    padding-bottom: calcRem(10);
    border-bottom: 1px solid $light-border-color;

    @media (max-width: $breakpoint-sm) {
      padding-bottom: calcRem(5);
    }
  }
  
  .groupTitle {
    font-size: calcRem(20);
    font-weight: 700;

    @media (max-width: $breakpoint-sm) {
      font-size: calcRem(16);
    }
  }
  
  .menuItem {
    font-weight: 400;
    
    a {
      color: $primary-text-color;

      @media (max-width: $breakpoint-sm) {
        font-size: calcRem(14);
      }

      &:hover {
        color: $primary-color;
      }
    }
  }
}