@import 'styles/helpers';

.carouselWrapper {
  display: flex;
  align-items: center;
  max-width: 90vw;

  @media (max-width: $breakpoint-sm) {
    padding: 0 calcRem(10);
    position: relative;
    max-width: 100%;
  }
}

.carouselInnerWrapper {
  overflow: hidden;
  flex-shrink: 1;
  flex-grow: 0;
  padding: calcRem(10);
}

.carouselControls {
  display: flex;
  align-items: center;
  
  @media (max-width: $breakpoint-sm) {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 10;

    &.controlLeft {
      left: 0;
    }

    &.controlRight {
      right: 0;
    }
  }
}

.carouselInner {
  display: flex;
  transition: all 0.15s cubic-bezier(.17,.67,.83,.67);
}

.slideItem {
  padding: 0 calcRem(10);
  display: flex;
  transition: all 0.2s ease-in-out;
  transform: scale(0.85);

  &.activeSlide {
    transform: scale(1);
  }
}