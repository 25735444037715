@import 'styles/helpers';

.indexUsages {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: calcRem(40);
  align-items: center;
  padding-top: calcRem(40);
  padding-bottom: calcRem(40);
}

.accordionContainer {
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: calcRem(10);
  padding-bottom: calcRem(40);
}

.accordionItem {
  list-style-type: none;

  .itemTitle {
    font-size: calcRem(28);
    font-weight: 900;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: $primary-color;
    }

    span {
      font-size: calcRem(14);
      color: $hint-text-color;
      display: inline-block;
    }
  }

  .itemContent {
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    max-height: 0;
    font-size: calcRem(14);
  }

  .itemCta {
    display: flex;
    align-items: center;
    font-size: calcRem(14);
    color: $primary-color;

    a {
      text-decoration: underline;
      font-weight: bold;
    }
  }
}

.qrFrameContainer {
  position: relative;
}

.qrExampleContainer {
  position: absolute;
  top: 20%;
  bottom: 0;
  left: 0;
  right: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
}