@import 'styles/helpers';

.pageWrapper {
  padding: 0 calcRem(30);
  overflow: hidden;
  
  @media (min-width: $breakpoint-sm) {
    display: grid;
    grid-template-rows: auto 1fr auto;
    min-height: 100vh;
  }

  @media (max-width: $breakpoint-sm) {
    padding: 0;
  }
}