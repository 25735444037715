@import 'styles/helpers';

.indexIntro {
  display: grid;
  grid-template-columns: calcRem(500) 1fr;
  grid-gap: calcRem(50);
  background-image: url('../public/images/index/index-main-bg-blob-min.svg');
  background-position: center top;
  background-repeat: no-repeat;
  margin-bottom: calcRem(-30);

  @media (max-width: $breakpoint-sm) {
    grid-template-columns: 1fr;
    background-size: contain;
    padding-bottom: calcRem(40);
  }

  .introContent {
    padding-left: calcRem(90);
    padding-top: calcRem(80);

    @media (max-width: $breakpoint-sm) {
      padding: 0;
    }
  }

  .indexBlobMask {
    mask-repeat: no-repeat;
    mask-image: url('../public/svgAssets/blobMask-indexMain-min.svg');
    mask-size: contain;
    padding-left: 5%;
    transform: scale(1.1);
  }

  .introDecoration {
    @media (max-width: 1200px) {
      margin-right: calcRem(-300);
    }

    @media (max-width: 1200px) {
      margin-left: calcRem(-100);
    }

    @media (max-width: $breakpoint-sm) {
      margin-bottom: calcRem(10);
      margin-top: calcRem(50);
      margin-left: 0;
      margin-right: 0;
      transform: scale(1.3) translateX(calcRem(20));
    }
  }
}

.indexTestAndSignUp {
  margin-top: calcRem(-50);

  @media (max-width: 1400px) {
    margin-top: 0;
  }

  @media (max-width: $breakpoint-sm) {
    margin: 0;
    overflow: hidden;
  }

  .qrInFrameSection {
    display: grid;
    align-items: end;
    grid-template-columns: calcRem(500) 1fr;
    grid-gap: calcRem(30);

    @media (max-width: 1400px) {
      grid-template-columns: calcRem(400) 1fr;
    }

    @media (max-width: $breakpoint-sm) {
      display: none;
    }

    .frameContainer {
      margin-left: calcRem(-60);
      position: relative;

      @media (max-width: 1400px) {
        margin-left: 0;
      }
    }

    .qrExampleContainer {
      position: absolute;
      top: 20%;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 1400px) {
        transform: scale(0.7);
      }
    }

    .frameCallToAction {
      padding-bottom: calcRem(50);
    }

    .handwrittenCta {
      display: block;
      font-family: 'Indie Flower', cursive;
      font-size: calcRem(32);
      max-width: calcRem(380);
      transform: rotate(10deg);
    }

    .swooshArrowContainer {
      display: block;
      width: calcRem(100);
    }
  }

  .qrBelowFrameSection {
    display: grid;
    grid-template-columns: 1fr;
    padding-top: calcRem(5);
    padding-bottom: calcRem(5);

    @media (max-width: $breakpoint-sm) {
      margin-left: calcRem(-15);
      margin-right: calcRem(-15);
      max-width: unset;
      width: unset;
    }

    .signupCtaStripCard {
      position: relative;
      overflow: hidden;
    }

    .signupCtaStrip {
      display: grid;
      grid-template-columns: 1fr calcRem(280);
      grid-gap: calcRem(20);
      align-items: center;
      padding-top: calcRem(40);
      padding-bottom: calcRem(40);
      position: relative;
      z-index: 2;
      max-width: calcRem(920);

      @media (max-width: 1000px) {
        grid-template-columns: 1fr;
        padding: calcRem(40) calcRem(50);
      }
    }

    .blobOne {
      display: block;
      position: absolute;
      width: calcRem(220);
      top: calcRem(20);
      left: calcRem(-100);
      opacity: 0.1;

      @media (max-width: $breakpoint-sm) {
        left: calcRem(-150)
      }
    }

    .blobTwo {
      display: block;
      position: absolute;
      width: calcRem(120);
      right: calcRem(-70);
      bottom: calcRem(-70);
      opacity: 0.1;
    }

    .blobThree {
      display: block;
      position: absolute;
      width: calcRem(200);
      top: calcRem(-120);
      right: calcRem(-100);
      transform: rotate(80deg);
      opacity: 0.1;
    }
  }
}


.tagElementsListStripe {
  padding: 0 calcRem(15);

  .tagElementsListStripeCard {
    padding-top: calcRem(60);
    padding-bottom: calcRem(60);
    position: relative;
    overflow: hidden;
  
    .blobOne {
      display: block;
      position: absolute;
      width: calcRem(220);
      top: calcRem(-100);
      left: calcRem(-80);
      opacity: 0.1;

      @media (max-width: $breakpoint-sm) {
        top: calcRem(-160);
      }
    }
  
    .blobTwo {
      display: block;
      position: absolute;
      width: calcRem(180);
      right: calcRem(-90);
      bottom: calcRem(-120);
      opacity: 0.1;
    }
  
    .blobThree {
      display: block;
      position: absolute;
      width: calcRem(240);
      top: calcRem(-100);
      right: calcRem(-100);
      transform: rotate(80deg);
      opacity: 0.1;

      @media (max-width: $breakpoint-sm) {
        top: calcRem(-160);
      }
    }
  
    .tagElementsListStripeContent {
      @media (max-width: $breakpoint-sm) {
        padding: 0 clacRem(20);
      }
    }
    
    .elementsList {
      display: grid;
      grid-template-columns: auto auto auto auto;
      grid-column-gap: calcRem(50);
      grid-row-gap: calcRem(20);
      padding: 0;
      margin: 0;

      @media (max-width: 1200px) {
        grid-template-columns: 1fr 1fr 1fr;
      }

      @media (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: $breakpoint-sm) {
        grid-template-columns: 1fr 1fr 1fr;
        overflow-x: scroll;
      }
    
      li {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: calcRem(15);
        align-items: center;
    
        span {
          font-weight: 800;
          color: $secondary-text-color;
          font-size: calcRem(14);
          text-transform: uppercase;
        }
      }
    }
  }

}